
import MainMenuConfig, { MenuItem } from "@/core/config/MainMenuConfig";
import { asideMenuIcons } from "@/core/helpers/config";
import { version } from "@/core/helpers/documentation";
import { usuarioTienePermiso } from "@/core/helpers/utils";
import { AuthModule } from "@/store/modules";
import { defineComponent, onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import { getModule } from "vuex-module-decorators";

export default defineComponent({
  name: "kt-menu",
  computed: {},
  methods: {
    /* filteredMenuItems es una propiedad calculada que:
      - recorre todos los elementos del menú (MainMenuConfig[0].pages)
      - devuelve un array de los que el usuario tiene permiso para ver, en función roleId.
    */
    getFilteredMenuItems(numMenu = 0): Array<MenuItem> | undefined {
      const store = useStore();
      const authModule = getModule(AuthModule, store);
      const user = authModule.user;

      if (MainMenuConfig[numMenu].pages !== undefined) {
        const itemsFiltrados = MainMenuConfig[numMenu]?.pages?.filter(
          (menuItem) => {
            var elUsuarioTienePermisoParaVerEsteItemDelMenu =
              menuItem.allowedRoles?.some(function (rolPermitido) {
                return usuarioTienePermiso(user, rolPermitido);
              }) || false;

            return elUsuarioTienePermisoParaVerEsteItemDelMenu;
          }
        );
        return itemsFiltrados;
      }
      return [];
    },
  },
  components: {},
  setup() {
    const route = useRoute();
    const scrollElRef = ref<null | HTMLElement>(null);

    onMounted(() => {
      if (scrollElRef.value) {
        scrollElRef.value.scrollTop = 0;
      }
    });

    const hasActiveChildren = (match) => {
      return route.path.indexOf(match) !== -1;
    };

    return {
      hasActiveChildren,
      MainMenuConfig,
      asideMenuIcons,
      version,
    };
  },
});
