
import { defineComponent } from "vue";
import KTMenu from "@/layout/aside/Menu.vue";
import { asideTheme } from "@/core/helpers/config";
const version = process.env.VUE_APP_VERSION;

export default defineComponent({
  name: "KTAside",
  components: {
    KTMenu,
  },
  props: {
    lightLogo: String,
    darkLogo: String,
  },
  setup() {
    return {
      asideTheme,
      version,
    };
  },
});
