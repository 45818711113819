export function usuarioTienePermiso(user: any, permiso: string): boolean {
  let result = false;
  switch (permiso) {
    case "*":
      result = true;
      break;
    case "superadmin":
      result = user.roleId === "SUPER_ADMIN";
      break;
    default:
      result = true;
      break;
  }
  return result;
}
