
import { computed, defineComponent } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { getModule } from "vuex-module-decorators";
import { AuthModule } from "@/store/modules";

export default defineComponent({
  name: "kt-user-menu",
  components: {},
  setup() {
    const router = useRouter();
    const store = useStore();
    const authModule = getModule(AuthModule, store);

    const signOut = () => {
      try {
        authModule.logOut();
      } catch (err) {
        console.error(err);
      }
      router.push({ name: "sign-in" });
    };

    const usuario = computed(() => {
      return authModule.user;
    });

    return {
      usuario,
      signOut,
    };
  },
});
